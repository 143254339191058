import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import { 
  LayoutPlugin,
  ModalPlugin, 
  CardPlugin,
  TableSimplePlugin,
  ListGroupPlugin,
  BCarousel,
  BCarouselSlide, 
  BIconFolderFill,
  BIconFacebook,
  BIconInstagram,
  BIconYoutube,
  BIconGeoAltFill,
  BIconEnvelopeFill,
  BIconSearch,
  BIconInfoCircle,
  BIconPlayBtnFill,
  BIconCart,
  BIconCheckCircle,
  BIconMoonFill,
	BIconSunFill,
  BIconMoon,
	BIconSun,
  AlertPlugin
} from 'bootstrap-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

detectColorScheme();

if(document.documentElement.getAttribute("data-theme") === "dark") {
	require('./themes/dark.scss');

	console.log("Loaded dark theme");
} else {
	require('./themes/light.scss')

	console.log("Loaded light theme");
}

// Make BootstrapVue available throughout your project
Vue.use(LayoutPlugin)
Vue.use(ModalPlugin)
Vue.use(CardPlugin)
Vue.use(TableSimplePlugin)
Vue.use(ListGroupPlugin)
Vue.use(AlertPlugin)
Vue.component('BCarousel', BCarousel)
Vue.component('BCarouselSlide', BCarouselSlide)
Vue.component('BIconFolderFill', BIconFolderFill)
Vue.component('BIconFacebook', BIconFacebook)
Vue.component('BIconInstagram', BIconInstagram)
Vue.component('BIconYoutube', BIconYoutube)
Vue.component('BIconGeoAltFill', BIconGeoAltFill)
Vue.component('BIconEnvelopeFill', BIconEnvelopeFill)
Vue.component('BIconSearch', BIconSearch)
Vue.component('BIconInfoCircle', BIconInfoCircle)
Vue.component('BIconPlayBtnFill', BIconPlayBtnFill)
Vue.component('BIconCart', BIconCart)
Vue.component('BIconCheckCircle', BIconCheckCircle)
Vue.component('BIconSunFill', BIconSunFill)
Vue.component('BIconMoonFill', BIconMoonFill)
Vue.component('BIconSun', BIconSun)
Vue.component('BIconMoon', BIconMoon)

Vue.use(VueAxios, axios)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


function detectColorScheme(){
	var theme = "light";    //default to light

	//local storage is used to override OS theme settings
	if(localStorage.getItem("theme")){
		if(localStorage.getItem("theme") == "dark"){
			theme = "dark";
		}
	} else if(!window.matchMedia) {
		//matchMedia method not supported
		return false;
	} else if(window.matchMedia("(prefers-color-scheme: dark)").matches) {
		//OS theme setting detected as dark
		theme = "dark";
	}

	//dark theme preferred, set document with a `data-theme` attribute
	document.documentElement.setAttribute("data-theme", theme);
}