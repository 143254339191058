import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Aktuelles from '../views/Aktuelles.vue'
// import Katalog from '../views/Katalog.vue'
// import Preise from '../views/Preise.vue'
// import About from '../views/About.vue'
// import Impressum from '../views/Impressum.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/aktuelles',
    name: 'Aktuelles',
    component: () => import('../views/Aktuelles.vue')
  },
  {
    path: '/katalog',
    name: 'Katalog',
    component: () => import('../views/Katalog.vue')
  },
  {
    path: '/preise',
    name: 'Preise',
    component: () => import('../views/Preise.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import('../views/Impressum.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0, behavior: 'smooth' }
  }
  
})

export default router
